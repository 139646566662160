import { render, staticRenderFns } from "./storeRetailRecord.vue?vue&type=template&id=1024fa06&scoped=true&"
import script from "./storeRetailRecord.vue?vue&type=script&lang=js&"
export * from "./storeRetailRecord.vue?vue&type=script&lang=js&"
import style0 from "./storeRetailRecord.vue?vue&type=style&index=0&id=1024fa06&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1024fa06",
  null
  
)

export default component.exports